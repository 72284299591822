import $ from 'jquery';
/* global ajax_object */
$(document).ready(function () {
  ltiWidget();
});

function ltiWidget() {
  $('.js-lti-widget').each(function () {});
  $('.category-tabs-list-togle').on('click', function () {
    let container = $(this).next();
    container.slideToggle();
    $(this).toggleClass('list-togle-active');
  });
  // $('.category-button').on('click', function (e) {
  //   e.preventDefault();
  //   $('.category-button').removeClass('category-button-active');
  //   $(this).addClass('category-button-active');
  //
  //   let categoryId = $(this).attr('data-category');
  //
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     type: 'POST',
  //     data: {
  //       action: 'load_lti_posts',
  //       category: categoryId,
  //     },
  //     success: function (response) {
  //       $('.lti-posts__container').html(response);
  //     },
  //   });
  // });
}
