// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';
/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
import '../blocks/elementor/card-widget/index.js'; import '../blocks/elementor/custom-features-widget/index.js'; import '../blocks/elementor/example-widget/index.js'; import '../blocks/elementor/hero-slider-widget/index.js'; import '../blocks/elementor/lti-tabs/index.js'; import '../blocks/elementor/partner-table/index.js'; import '../blocks/elementor/team-popup-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/* global ajax_object */
/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  if (document.querySelector('.tabs-green') && window.innerWidth > 1024) {
    initializeTabs();
  }

  // if ($('.box-hover').length > 0) {
  //   const counters = document.querySelectorAll('.value');
  //   const speed = 100;
  //
  //   counters.forEach((counter) => {
  //     const animate = () => {
  //       const value = +counter.getAttribute('akhi');
  //       const data = +counter.innerText;
  //
  //       const time = value / speed;
  //       if (data < value) {
  //         counter.innerText = Math.ceil(data + time);
  //         setTimeout(animate, 1);
  //       } else {
  //         counter.innerText = value;
  //       }
  //     };
  //     animate();
  //   });
  // }
  // if ($('.box-hover').length > 0) {
  //   const counters = document.querySelectorAll('.value');
  //   const speed = 5000; // Increase speed to slow down the animation
  //
  //   counters.forEach((counter) => {
  //     const animate = () => {
  //       const value = +counter.getAttribute('akhi');
  //       const data = +counter.innerText;
  //
  //       const increment = value / (speed / 10); // Adjust the increment for smoother animation
  //       if (data < value) {
  //         counter.innerText = Math.ceil(data + increment);
  //         setTimeout(animate, 10); // Increase the timeout interval for smoother animation
  //       } else {
  //         counter.innerText = value;
  //       }
  //     };
  //     animate();
  //   });
  // }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.menu-item-has-children').hover(
    function () {
      $('header').addClass('header-custom-active');
      $(this).addClass('parrent-active');
      $('.elementor-widget-theme-site-logo').hide();
      $('.hover-logo').show();
    },
    function () {
      if ($(window).scrollTop() === 0) {
        $('header').removeClass('header-custom-active');
      }
      $(this).removeClass('parrent-active');
      if ($(window).scrollTop() === 0) {
        $('.elementor-widget-theme-site-logo').show();
        $('.hover-logo').hide();
      }
    }
  );

  $('.elementor-menu-toggle__icon--open').on('click', function () {
    $('header').addClass('header-mobile-active');
    $('.elementor-widget-theme-site-logo').addClass('hide');
    $('.hover-logo').addClass('show');
  });
  $('.elementor-menu-toggle__icon--close').on('click', function () {
    $('header').removeClass('header-mobile-active');
    $('.elementor-widget-theme-site-logo').removeClass('hide');
    $('.hover-logo').removeClass('show');
  });

  $('.elementor-nav-menu--dropdown .main-menu-item').on('click', function () {
    $(this).toggleClass('main-menu-item-mob-active');

    if ($(this).hasClass('main-menu-item-mob-active')) {
      let submenu = $(this).find('.mega-menu');
      submenu.slideDown();
    } else {
      let submenu = $(this).find('.mega-menu');
      submenu.slideUp();
    }
  });
  if ($('.grid-slider').length > 0 && $(window).width() <= 1024) {
    initializeSlick();
  }

  jQuery(function ($) {
    $('.post-category-filter__button').click(function () {
      var category = $(this).data('category');
      var data = {
        action: 'filter_blog_posts',
        category: category,
        page: 1,
      };

      $.post(ajax_object.ajax_url, data, function (response) {
        $('.elementor-posts-container').html(response);
      });
    });

    $(document).on('click', '.pagination a', function (e) {
      e.preventDefault();

      var page = $(this).data('page');
      var category = $('.category-filter.active').data('category');

      var data = {
        action: 'filter_blog_posts',
        category: category,
        page: page,
      };

      $.post(ajax_object.ajax_url, data, function (response) {
        $('.elementor-posts-container').html(response);
      });
    });
  });
});

let header = document.querySelector('header');

function checkScroll() {
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  if (scrollTop === 0) {
    header.classList.remove('header-custom-active');
    $('.elementor-widget-theme-site-logo').show();
    $('.hover-logo').hide();
  } else {
    header.classList.add('header-custom-active');
    $('.elementor-widget-theme-site-logo').hide();
    $('.hover-logo').show();
  }
}

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

function initializeSlick() {
  $('.grid-slider .e-con-inner').slick({
    dots: false,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 3000,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '100px',
        },
      },
    ],
  });
}

function initializeTabs() {
  const tabTitles = document.querySelectorAll('.elementor-tab-title');
  const tabContents = document.querySelectorAll('.elementor-tab-content');
  let activeTab = document.querySelector(
    '.elementor-tab-title.elementor-active'
  );
  let activeContent = document.querySelector(
    '.elementor-tab-content.elementor-active'
  );

  function clearActiveClasses() {
    tabTitles.forEach((title) => {
      title.classList.remove('elementor-active');
      title.setAttribute('aria-selected', 'false');
      title.setAttribute('aria-expanded', 'false');
    });
    tabContents.forEach((content) => {
      content.classList.remove('elementor-active');
      content.hidden = true;
    });
  }

  function activateTab(tab) {
    const tabNum = tab.getAttribute('data-tab');
    const contentToShow = document.querySelector(
      `.elementor-tab-content[data-tab="${tabNum}"]`
    );

    tab.classList.add('elementor-active');
    tab.setAttribute('aria-selected', 'true');
    tab.setAttribute('aria-expanded', 'true');
    contentToShow.classList.add('elementor-active');
    contentToShow.hidden = false;

    activeTab = tab;
    activeContent = contentToShow;
  }

  tabTitles.forEach((title) => {
    title.addEventListener('mouseenter', function () {
      if (window.innerWidth > 1024) {
        clearActiveClasses();
        activateTab(this);
      }
    });
  });

  // Ensure the last active tab remains active
  document
    .querySelector('.elementor-tabs-wrapper')
    .addEventListener('mouseleave', function () {
      if (activeTab && activeContent && window.innerWidth > 1024) {
        activeTab.classList.add('elementor-active');
        activeTab.setAttribute('aria-selected', 'true');
        activeTab.setAttribute('aria-expanded', 'true');
        activeContent.classList.add('elementor-active');
        activeContent.hidden = false;
      }
    });
}

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  if ($('.grid-slider').length > 0 && $(window).width() <= 1024) {
    if (!$('.grid-slider').hasClass('slick-initialized')) {
      initializeSlick();
    }
  } else {
    if ($('.grid-slider').hasClass('slick-initialized')) {
      $('.grid-slider').slick('unslick');
    }
  }
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  checkScroll();
});
document.addEventListener('DOMContentLoaded', () => {
  // Check if there is any section containing .value elements
  if ($('.box-hover').length > 0) {
    const counters = document.querySelectorAll('.value');
    const speed = 2000; // Increase speed to slow down the animation

    const animate = (counter) => {
      const value = +counter.getAttribute('akhi');
      const data = +counter.innerText;

      const increment = value / (speed / 10); // Adjust the increment for smoother animation
      if (data < value) {
        counter.innerText = Math.ceil(data + increment);
        setTimeout(() => animate(counter), 50); // Increase the timeout interval for smoother animation
      } else {
        counter.innerText = value;
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animate(entry.target);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    counters.forEach((counter) => {
      observer.observe(counter);
    });
  }
});
